import React from "react";
// @ts-ignore
import Seo from "../utils/seo";

// @ts-ignore
import Layout from "../layouts";
import EmpowerLeadership from "../sections/EmpowerLeadership";
import AIxHW from "../sections/AIxHW";
import AICoach from "../sections/AICoach";
import AIinAction from "../sections/AIinAction";
import AIExp from "../sections/AIExperience";
import AIfeatures from "../sections/AIFeatures";
import AIPersonalized from "../sections/AIPersonalized";
import AICoaching from "../sections/AICoaching";

const IndividualCoaching = () => {
  return (
    <>
      <Seo
        title="AI Solutions for Professional Coaching | Coachello" description="Empower coaching with Coachello's AI solutions—enhancing efficiency, personalization, and growth."
        // image={seoImg}
      />
      <Layout>
        <EmpowerLeadership />
        <AIxHW />
        <AICoach />
        <AIinAction />
        <AIfeatures />
        <AIExp />
        <AIPersonalized />
        <AICoaching />
      </Layout>
    </>
  );
};

export default IndividualCoaching;
